.hero.interior-hero {
  position: relative;
  padding: 10vh 0;
  height: auto;
  overflow: visible;
  @include laptop {
    padding: 20vh 0 10vh;
  }
  &.no-img {
    background: $white!important;
    padding-top: 2vh;
    padding-bottom: 0;
    @include laptop {
      padding-top: 5vh;
    }
    .hero-content {
      margin: 0;
      padding: 30px 0;
      background: none;
      border: none;
      h1 {
        margin-bottom: 20px;
        @include desktop {
          margin-bottom: 40px;
        }
      }
    }
  }
  &:before {
    display: none;
  }
  .hero-content {
    z-index: 100;
    position: relative;
    text-align: center;
    h1 {
      text-transform: none;
      margin-bottom: 20px;
      font-size: 24px;
      @include mobile {
        font-size: 28px;
      }
      @include desktop {
        font-size: 32px;
      }
    }
  }

  .hero-img {
    position: relative;
    z-index: 100;
    margin-bottom: -40px;
    border: 4px solid #fff;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.75);
  }
}

.contact {
  .page-content {
    background: linear-gradient(rgba($brand-primary,0.9),rgba($brand-primary,0.9)), url(../images/bkg.jpg) no-repeat fixed center;
    background-size: cover;
    h1,h2,h3,h4,p,li,a {
      color: #fff;
    }
    .btn.btn-primary {
      border-color: #fff;
      background-color: transparent;
      color: #fff;
      width: auto;
      &:hover, &:focus {
        border-color: #fff;
        color: $brand-primary;
        background-color: #fff;
      }
    }
  }
  .wpcf7 {
    .wpcf7-form {

      .btn.btn-primary {
        width: 259px;
        border-color: #fff;
        color: #fff;
        &:hover, &:focus {
          border-color: #fff;
          color: $brand-primary;
          background-color: #fff;
        }
      }
    }
  }
}

.contact-us-at-er-weartech {
  .er-weartech {
    img, h1 {
      display: inline-block;
    }

    h1 {
      margin-bottom: 60px;
    }
  }
}

.about {
  .hero.interior-hero {
    border-bottom: none;
  }
}

.page-content {
  padding: 40px 0;

  box-shadow: inset 0 8px 8px -8px rgba(0,0,0,0.35), inset 0 -8px 8px -8px rgba(0,0,0,0.35);

  .img-thumbnail {
    background-color: $brand-primary;
    border: none;
    border-radius: 0;
  }
  h2 {
    text-transform: none;
    margin-bottom: 20px;
    margin-top: 35px;
  }
  blockquote {
    background: white;
    border-radius: 8px;
    margin-top: 20px;
    // text-align: center;
    padding: 20px;
    border-left: none;
    p {
      margin-bottom: 8px;
      font-size: 14px;
      font-style: italic;
    }
    img {
      width: 35px;
    }
    @include laptop {

    .quote {
        text-align: left;
      }
      .author, .quote-img {
        display: inline-block;
        vertical-align: top;
      }
      .author {
        font-weight: bold;
        font-size: 12px;
      }
      img {
        margin-right: 10px;
      }
    }

  }

  &.blog {
    background: linear-gradient(rgba($brand-primary,0.9),rgba($brand-primary,0.9)), url(../images/bkg.jpg) no-repeat fixed center;
    background-size: cover;
  }


  &.portfolio-content {
    background: linear-gradient(rgba($brand-primary,0.9),rgba($brand-primary,0.9)), url(../images/bkg.jpg) no-repeat fixed center;
    background-size: cover;
    h1,h2,h3,h4,p,li,a {
      color: #fff;
    }
    #portfolioCarousel,#portfolioCarousel2,#portfolioCarousel3,#portfolioCarousel4 {
      width: 100%;
      margin-left: 0;
      .carousel-inner {
        @include laptop {
          width: 55%;
          display: inline-block;
        }
        .caption p a:last-of-type {
          display: none;
        }
      }
      .carousel-control {
        background-color: #fff;
        top: 46%;
        .fa {
          color: $brand-primary;
        }
        @include laptop {
          &.right {
            right: 43%;
          }
        }
      }

      .carousel-indicators {
        width: auto;
        display: block;
        position: relative;
        text-align: left;
        left: 0;
        font-size: 0;
        padding-top: 30px;
        right: 0;
        margin-bottom: 20px;
        margin-left: -10px;
        margin-right: -10px;
        @include laptop {
          width: 40%;
          padding-top: 0;
          left: auto;
          float: right;
          top: 0;
          bottom: auto;
          margin-left: -5px;
          margin-right: -5px;
        }
        li {
          width: 20%;
          padding: 0 10px;
          margin-bottom: 16px;
          @include laptop {
            padding: 0 5px;
            margin-bottom: 11px;
          }
          &.fifth {
            margin-right: 0;
          }
        }
      }

    }
  }

  &.landing {
    box-shadow: inset 0 8px 8px -8px rgba(0,0,0,0.35), inset 0 -8px 8px -8px rgba(0,0,0,0.35);
    padding: 40px 10px;
    background: #f7f7f7;
    position: relative;
    @include tablet {
      padding: 40px 0;
      &:before {
        content: '';
        background: url(../images/interior-bkg.svg) no-repeat top;
        background-size: cover;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 0.05;
      }
    }

  }

  h1 {
    margin-bottom: 30px;
  }
  p, ul, ol {
    margin-bottom: 20px;
  }
  #portfolioCarousel,#portfolioCarousel2,#portfolioCarousel3,#portfolioCarousel4 {
    margin-bottom: 30px;
    @include laptop {
      margin-bottom: 0;
    }
  }

  &.about {
    padding-top: 60px;
    z-index: -1;
  }
}

.strapline.interior-strap {
  // border-top: 10px solid $brand-primary;
  padding: 30px 0;
  &.offset-top {
    padding: 40px 0 30px;

  }

  p {
    font-size: 18px;
    line-height: 26px;
    @include tablet {
      font-size: 20px;
      line-height: 30px;
    }
    @include laptop {
      font-size: 22px;
      line-height: 34px;
    }
  }
}

.inner-error404 {
    padding-top: 40px;
    padding-bottom: 40px;
}
