.prototypes-sidebar {
  text-align: center;
  figure.wp-caption {
    max-width: 95%;
    width: 435px;
    margin: 0 auto 20px;
    @include laptop {
      margin: 0 0 20px;
    }
  }
}

blockquote {
  @include tablet {
    max-width: 95%;

  }
}
