.hero  {
  background-image: url(../images/hero.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  border-bottom: 10px solid $brand-primary;
  position: relative;
  text-align: center;
  padding: 6vh 0 15vh;
  height: 70vh;
  z-index: 0;
  overflow: hidden;
  @include tablet {
    height: 80vh;

  }
  @include desktop {
    padding: 6vh 0 18vh;
  }

  .extra-vp {
    text-transform: none;
    margin-bottom: 8px;
    text-align: center;
  }


  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    display: block;
    top: -20px;
    bottom: -20px;
    left: -20px;
    right: -20px;
    filter: blur(8px);
    background-image: url(../images/hero.jpg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    @include tablet {
      display: none;
    }
  }
  .hero-content {
    text-align: center;
    margin: 0 -15px;
    background-color: rgba($grey,0.8);
    border: 1px solid $grey;
    border-radius: 3px;
    padding: 25px;
    @include laptop {
      text-align: left;
    }
    @include desktop {
      padding: 30px;

    }
    h1 {
      font-size: 30px;
      @include mobile {
        font-size: 32px;
      }
      @include desktop {
        font-size: 46px;
      }
    }
    p {
      font-size: 20px;
      line-height: normal;
      margin-bottom: 15px;
      color: $heading;
      @include mobile {
        font-size: 22px;
      }
      @include desktop {
        font-size: 24px;
      }
    }
  }
}

.strapline {
  background: linear-gradient(rgba(229,229,229,0.8),rgba(229,229,229,0.8)), url(../images/strapline.jpg) no-repeat center;
  background-size: cover;
  text-align: center;
  border-bottom: 1px solid $grey;
  p {
    margin-bottom: 0;
    color: $blue;
    line-height: normal;
    font-size: 20px;
    @include tablet {
      font-size: 24px;
    }
    @include laptop {
      font-size: 26px;
      line-height: 36px;
    }
  }
}

.innovation {
  position: relative;
  padding: 50px 10px;
  @include tablet {
    padding: 50px 0;
  }
  &:before {
    content: '';
    background: url(../images/innovation.svg) no-repeat center;
    background-size: cover;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
      z-index: 0;
    opacity: 0.03;
  }

  #portfolioCarousel {
    @include laptop {
      width: 100%;
    }

    .portfolio-img {
      height: 250px;

      @include phablet {
        height: 350px;
      }

      @include tablet {
        height: 500px;
      }

      @include laptop {
        height: 407px;
      }

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  .btn.btn-outline {
    font-size: 16px;
    width: auto;
    @include mobile {
      font-size: 18px;
    }
    @include tablet {
      font-size: 20px;
    }
  }
}

.services {
  background: #f9f9f9;
  overflow: hidden;
  position: relative;
  box-shadow: inset 0 8px 8px -8px rgba(0,0,0,0.35), inset 0 -8px 8px -8px rgba(0,0,0,0.35);

  &:before {
    content: '';
    background: url(../images/services.svg) no-repeat top;
    background-size: cover;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.07;
  }

  .service-img {
    height: 250px;

    @include phablet {
      height: 400px;
    }

    @include tablet {
      height: 258px;
    }

    @include laptop {
      height: 252px;
    }

    @include desktop {
      height: 218px;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    .caption {
      background-color: rgba(0,0,0,0.5);
      padding: 15px;
      position: absolute;
      bottom: 0;
      z-index: 5;
      left: 15px;
      right: 0;
      color: #fff;
      transition: all 0.5s ease-in-out;
      top: auto;
      overflow: hidden;
      margin-right: 15px;

      @include laptop {
        top:83%;
      }
      @include desktop {
        top:80%;
      }
      h3, p, a {
        color: #fff;
      }
      h3 {
        margin-top: 0;
        margin-bottom: 15px;
      }
      p {
        margin-bottom: 15px;
      }
    }

    &:hover, &:focus {
      .caption {
        @include laptop {
          top: 0;
        }
      }
    }
  }
}

.how {
  background: linear-gradient(rgba($blue,0.85),rgba($blue,0.85)), url(../images/work.png) no-repeat center;
  background-size: cover;
  color: #fff;

  h2, h4, p {
    color: #fff;
  }

  .desc {
    text-align: center;
    @include laptop {
      text-align: left;
    }
    h2 {
      margin-top: 0;
    }
    p {
      margin-bottom: 40px;
    }
  }

  .btn.btn-outline {
    border-color: #fff;
    color: #fff;
    &:hover, &:focus {
      border-color: #fff;
      color: $brand-primary;
      background-color: #fff;
    }
  }

  .step {
    text-align: center;
    .fa {
      font-size: 50px;
      margin-top: 30px;
    }
    p {
      font-size: 14px;
      max-width: 215px;
      margin: 0 auto;
    }
  }
}
