.single {
  .page-content.blog {
    .product {
      position: relative;
      z-index: 10;
      .portfolio-img {
        @include aspect-ratio(6,4);
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
      h1 {
        text-transform: none;
        font-size: 32px;
        @include desktop {
          font-size: 36px;
        }
      }
      .content:before {
        display: none;
      }
    }
  }
}


.page-content.blog {
  h1 {
    color: #fff;
    text-transform: none;
  }

  &.single {
    .post .content {
      .publish-date {
        margin-bottom: 25px;
      }

      &:before {
        display: none;
      }
    }
  }
  .post, .product {
    box-shadow: 0 0 0 2px #000;
    border-radius: 4px;
    // overflow: hidden;
    margin-bottom: 30px;

    h1 {
      color: #000;
      margin-bottom: 10px;
    }

    .blog-img-container{
      position: relative;
      overflow: hidden;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }

    .blog-img {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      @include aspect-ratio(6,4);
      @include tablet {
        @include aspect-ratio(10,5);
      }
    }
      .blog-blur {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        top: -20px;
        left: -20px;
        right: -20px;
        bottom: -20px;
        filter: blur(30px);
      }

    .content {
      z-index: 10;
      position: relative;
      background-color: #fff;
      padding: 20px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      &:before {
        content: '';
        background-image: url('../images/logo-icon-blue.svg');
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.05;
        @include tablet {
          background-size: 63%;
        }
        @include desktop {
          background-size: 50%;
        }
      }

      h3.entry-title {
        margin-bottom: 6px;
        margin-top: 0;
      }

      .publish-date {
        margin-bottom: 10px;
      }

      .entry-summary {
        p {
          margin-bottom: 0;
          a:last-of-type {
            @extend .btn;
            @extend .btn-primary;
            display: block;
            font-size: 16px;
            width: 160px;
            padding: 8px;
            margin-top: 20px;
            position: relative;
            z-index: 11;
          }
        }
      }
    }


  }
  .blog-sidebar {
    box-shadow: 0 0 0 2px #000;

    position: relative;
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    overflow: hidden;
    .content {
      ul {
        list-style-type: none;
        padding-left: 0;
        li {
          padding: 8px 0;
          border-bottom: 1px solid $white;
          line-height: normal;
        }
      }
    }
    .widget {
      margin-bottom: 30px;
      @include tablet {
        width: 50%;
        float: left;
      }
      @include laptop {
        float: none;
        width: 100%;
      }
      &.portfolio-widget {
        width: 100%;
        #portfolioCarousel {
          @include laptop {
            width: 100%;
          }
          .carousel-control {
            display: none;
          }
        }
      }
    }

  }
}


.post-nav {
  text-align: center;
  .prev-post {
    float: right;
  }
  .next-post {
    float: left;
  }
}
