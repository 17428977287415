.btn {
  text-transform: uppercase;
  font-size: 20px;
  width: 259px;
  max-width: 100%;
  padding: 11px 20px;
  border-radius: 3px;
  transition: all 0.5s ease-in-out;

  &.btn-primary {
    border: none;
    color: #fff;
    border: 1px solid transparent;
    &:hover, &:focus {
      background-color: rgba($brand-primary,0.8);
      border: 1px solid $brand-primary;
      color: #fff;
    }
  }

  &.btn-outline {
    background-color: transparent;
    border: 1px solid $brand-primary;
    color: $brand-primary;
    &:hover, &:focus {
      background-color: $brand-primary;
      color: #fff;
    }
  }
}
