.wpcf7 {
  .wpcf7-form {
    p {
      margin-bottom: 5px;
    }
    .wdm-nocapt-recapt {
      margin-bottom: 20px;
    }
    .btn.btn-primary {
      @extend .btn-outline;

    }
    .wpcf7-file {
      color: #fff;
    }
  }
}

.gform_wrapper {
  ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .gform_body{
    #field_1_5 label{
      display: none;
    }
    label {
      font-weight: 400;
    }
    input:not([type=file]), textarea{
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 100%;
      border-radius: 4px;
      padding: 6px 12px;
      border: 1px solid #ccc;
      box-shadow: none;
      margin-bottom: 15px;
      color: #1d3453;
    }
    textarea{
      height: 160px;
      color: #1d3453;
    }
  }
  .gform_footer input[type=submit]{
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-outline;
    width: 259px !important;
  }

}