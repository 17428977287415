body {
  font-size: 16px;
  font-family: $brand-font;
  font-weight: 400;
}

p, li {
  font-size: 16px;
  font-family: $brand-font;
  font-weight: 400;
  line-height: 24px;
  color: $para;
}

p {
  font-family: $brand-font;
  margin-bottom: 30px;
}

img {
  max-width: 100%;
  height: auto;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $brand-font;
  color: $heading;
}

h1 {
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 0;
  @include desktop {
    font-size: 40px;

  }
}

h2 {
  font-size: 30px;
  margin-bottom: 30px;
  font-weight: 400;
  text-transform: uppercase;
}

h3 {
  font-size: 20px;
  font-weight: 500;
}

h4 {
  font-weight: 400;
  font-size: 18px;
}

.navbar-logo {
  display: block;
}

.section-panel {
  padding: 50px 0;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
}

.video-container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

// Show many carousel
.carousel-showmanymoveone {

  .carousel-control {
    width: 4%;
    background-image: none;
  }
   .carousel-control.left {
    margin-left: 15px;
  }
   .carousel-control.right {
    margin-right: 15px;
  }
   .cloneditem-1,
   .cloneditem-2,
   .cloneditem-3,
   .cloneditem-4 {
    display: none;
  }

  .item-content {
    width: 100%;
    text-align: center;
    @include mobile {
      float: left;
      width: calc(100% / 2);
    }
    @include tablet {
      width: calc(100% / 3);
    }
    @include laptop {
      width: calc(100% / 4);
    }
    @include desktop {
      width: calc(100% / 5);
    }
  }

  @include mobile {
    .carousel-inner > .active.left,
    .carousel-inner > .prev {
     left: calc(-100% / 2);
   }
    .carousel-inner > .active.right,
    .carousel-inner > .next {
     left: calc(100% / 2);
   }
    .carousel-inner > .left,
    .carousel-inner > .prev.right,
    .carousel-inner > .active {
     left: 0;
   }

   .carousel-inner .cloneditem-1 {
     display: block;
   }

  }

  @media all and (min-width: 480px) and (transform-3d), all and (min-width: 480px) and (-webkit-transform-3d) {

     .carousel-inner > .item.active.right,
     .carousel-inner > .item.next {
      -webkit-transform: translate3d(calc(100% / 2), 0, 0);
      transform: translate3d(calc(100% / 2), 0, 0);
      left: 0;
    }
     .carousel-inner > .item.active.left,
     .carousel-inner > .item.prev {
      -webkit-transform: translate3d(calc(-100% / 2), 0, 0);
      transform: translate3d(calc(-100% / 2), 0, 0);
      left: 0;
    }
     .carousel-inner > .item.left,
     .carousel-inner > .item.prev.right,
     .carousel-inner > .item.active {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      left: 0;
    }
  }
  @media all and (min-width: 768px) {
     .carousel-inner > .active.left,
     .carousel-inner > .prev {
      left: calc(-100% / 3);
    }
     .carousel-inner > .active.right,
     .carousel-inner > .next {
      left: calc(100% / 3);
    }
     .carousel-inner > .left,
     .carousel-inner > .prev.right,
     .carousel-inner > .active {
      left: 0;
    }
     .carousel-inner .cloneditem-1,
     .carousel-inner .cloneditem-2 {
      display: block;
    }
  }
  @media all and (min-width: 768px) and (transform-3d), all and (min-width: 768px) and (-webkit-transform-3d) {
     .carousel-inner > .item.active.right,
     .carousel-inner > .item.next {
      -webkit-transform: translate3d(calc(100% / 3), 0, 0);
      transform: translate3d(calc(100% / 3), 0, 0);
      left: 0;
    }
     .carousel-inner > .item.active.left,
     .carousel-inner > .item.prev {
      -webkit-transform: translate3d(calc(-100% / 3), 0, 0);
      transform: translate3d(calc(-100% / 3), 0, 0);
      left: 0;
    }
     .carousel-inner > .item.left,
     .carousel-inner > .item.prev.right,
     .carousel-inner > .item.active {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      left: 0;
    }
  }
  @media all and (min-width: 992px) {
     .carousel-inner > .active.left,
     .carousel-inner > .prev {
      left: -25%;
    }
     .carousel-inner > .active.right,
     .carousel-inner > .next {
      left: 25%;
    }
     .carousel-inner > .left,
     .carousel-inner > .prev.right,
     .carousel-inner > .active {
      left: 0;
    }
     .carousel-inner .cloneditem-2,
     .carousel-inner .cloneditem-3 {
      display: block;
    }
  }
  @media all and (min-width: 992px) and (transform-3d), all and (min-width: 992px) and (-webkit-transform-3d) {
     .carousel-inner > .item.active.right,
     .carousel-inner > .item.next {
      -webkit-transform: translate3d(25%, 0, 0);
      transform: translate3d(25%, 0, 0);
      left: 0;
    }
     .carousel-inner > .item.active.left,
     .carousel-inner > .item.prev {
      -webkit-transform: translate3d(-25%, 0, 0);
      transform: translate3d(-25%, 0, 0);
      left: 0;
    }
     .carousel-inner > .item.left,
     .carousel-inner > .item.prev.right,
     .carousel-inner > .item.active {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      left: 0;
    }

  }

  @media all and (min-width: 1200px) {
     .carousel-inner > .active.left,
     .carousel-inner > .prev {
      left: -20%;
    }
     .carousel-inner > .active.right,
     .carousel-inner > .next {
      left: 20%;
    }
     .carousel-inner > .left,
     .carousel-inner > .prev.right,
     .carousel-inner > .active {
      left: 0;
    }
     .carousel-inner .cloneditem-2,
     .carousel-inner .cloneditem-3,
     .carousel-inner .cloneditem-4 {
      display: block;
    }
  }
  @media all and (min-width: 1200px) and (transform-3d), all and (min-width: 1200px) and (-webkit-transform-3d) {
     .carousel-inner > .item.active.right,
     .carousel-inner > .item.next {
      -webkit-transform: translate3d(20%, 0, 0);
      transform: translate3d(20%, 0, 0);
      left: 0;
    }
     .carousel-inner > .item.active.left,
     .carousel-inner > .item.prev {
      -webkit-transform: translate3d(-20%, 0, 0);
      transform: translate3d(-20%, 0, 0);
      left: 0;
    }
     .carousel-inner > .item.left,
     .carousel-inner > .item.prev.right,
     .carousel-inner > .item.active {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      left: 0;
    }

  }
}



#portfolioCarousel,#portfolioCarousel2,#portfolioCarousel3,#portfolioCarousel4 {
  @include tablet {
    width: 95%;
    margin: 0;
  }
  @include laptop {
    margin: 0;
  }
  /* Indicators list style */
  .carousel-indicators {
      left: 0;
      display: none;

      @extend .row;
      margin-left: -5px;
      margin-right: -5px;
      @include tablet {
        display: block;
        bottom: auto;
        width: auto;
        position: relative;
        padding-top: 30px;
      }

  }

  /* Indicators list style */
   .carousel-indicators li {
      display: block;
      border:  none;
      border-radius: 0;
      float: left;
      padding-left: 5px;
      padding-right: 5px;
      height: auto;
      margin: 0 0 5px;
      width: 20%;
      background: transparent;

  }
  /* Indicators images style */
   .carousel-indicators div {
      border: 2px solid #FFFFFF;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

  }
  /* Indicators active image style */
   .carousel-indicators .active div {
      border: 2px solid $brand-primary;
      opacity: 0.7;
  }

  .portfolio-img {
    .caption {
      background: rgba(0,0,0,0.5);
      padding: 15px;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      text-align: center;

      h3 {
        color: #fff;
        margin-top: 0;
        margin-bottom: 15px;
      }

      .btn.btn-outline {
        font-size: 16px;
        text-transform: none;
        border-color: #fff;
        width: auto;
        padding: 6.5px 20.5px;
        color: #fff;
        &:hover, &:focus {
          border-color: #fff;
          color: $brand-primary;
          background-color: #fff;
        }
      }
    }
  }

  .carousel-control {
    z-index: 100;
    background: $brand-primary;
    border-radius: 50%;

    bottom: auto;
    opacity: 1;
    top: calc(50% - 17.5px);
    padding: 1px;
    width: 35px;
    height: 35px;

    @include tablet {
      padding: 10px;
      top: 32%;
      width: 50px;
      height: 50px;
    }

    .fa {
      font-size: 15px;
      text-shadow: none;
      @include tablet {
        font-size: 20px;
      }
    }

    &.left  {
      left: -17.5px;
      @include tablet {
        left: -25px;
      }
      .fa {
        margin-right: 4px;
      }
    }

    &.right {
      right: -17.5px;
      @include tablet {
        right: -25px;
      }
      .fa {
        margin-left: 4px;
      }
    }
  }

  .portfolio-img-1 {
    background-image: url(../images/RivGen-for-ORPC-prototype-project.jpg);
  }
  .portfolio-img-2 {
    background-image: url(../images/Autoclavable_Stainless_Steel_Cart_for_Research_Facilities.jpg);
  }
  .portfolio-img-3 {
    background-image: url(../images/Buoy-for-Univeristy-of-Maines-offshore-marine-project.jpg);
  }
  .portfolio-img-4 {
    background-image: url(../images/Fastcos-boiler-plug.jpg);
  }
  .portfolio-img-5 {
    background-image: url(../images/Back-Stand-for-paper-converting-facility.jpg);
  }
}

body.portfolio,
.page-template-template-interior-page {
  .item {
    .portfolio-img {
      @include aspect-ratio(47,42);
    }
  }

  .portfolio-img {
    @include aspect-ratio(7,6);
  }
}

.portfolio-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  overflow: hidden;
  .portfolio-blur {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: -1;
    top: -20px;
    left: -20px;
    right: -20px;
    bottom: -20px;
    filter: blur(30px);
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .portfolio-img .portfolio-blur {
        background-image: none !important;
        background-color: #000;
    }
}

@supports (-ms-accelerator:true) {
    .portfolio-img .portfolio-blur {
        background-image: none !important;
        background-color: #000;
    }
}
