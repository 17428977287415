.banner {
  background-color: $white;
  .navbar-logo {
    margin-top: 25px;
    max-width: 300px;
    @include mobile {
      margin-top: 15px;
    }
    @include tablet {
      margin-top: 20px;
    }
    @include laptop {
      margin-top: 15px;
      max-width: 100%;
    }
    @include desktop {
        margin-top: 10px;
    }
  }
  @include laptop {
    padding: 10px 0;
  }


  .nav {
    li {
      a {
        // font-family: $heading-font;
        letter-spacing: 0.04em;
        font-weight: 400;
        padding: 8px;
        font-size: 13px;
        @include desktop {
          padding: 8px 10px;
          font-size: 13px;
        }
        &:hover, &:focus {
          background-color: transparent;
        }
      }
    }
  }


  .primary {
    .nav {
      li {
        a {
          padding: 10px 15px;
          position: relative;
          z-index: 1;
          text-transform: uppercase;
          font-size: 16px;
          @include desktop {
            padding: 10px 15px;

          }



        }
      }
    }
  }

}
