.footer-map iframe {
    width: 100%;
    height: 360px;
    border: 0;
}

.work-with-us {
  text-align: center;
  padding-bottom: 0;
  @include tablet {
    padding-bottom: 50px;
  }

  #clientCarousel {
    margin-bottom: 30px;

    h2 {
      margin-top: 0;
      max-width: 80%;
      text-align: left;
      @include tablet {
        text-align: center;
        max-width: 100%;
      }
    }

    .carousel-control {
      text-shadow: none;
      opacity: 1;
      color: #041e41;
      width: auto;
      bottom: auto;
      top: 5px;
      .fa {
        font-size: 24px;
      }
      &.left {
        left: auto;
        right: 40px;
        margin-left: 0;
        @include tablet {
          right: 25px;
        }
      }
      &.right {
        margin-right: 0;
        right: 15px;
        @include tablet {
          right: 0;
        }
      }
    }

  }

  #testimonialCarousel {
    background-color: #f5f5f5;
    padding: 65px 30px 30px;
    @include tablet {
      padding: 30px 140px;
    }
    &:before {
      content: '\f10d';
      font-family: FontAwesome;
      font-size: 30px;
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      top: 20px;
      @include tablet {
        top: 24px;
        right: auto;
        left: 50px;
      }
    }

    .quote {
      min-height: 75px;
      margin-bottom: 8px;
    }

    .quote-author {
      position: relative;
    }

    .company-logo {
      max-height: 60px;
      margin-right: 8px;
    }

    .author {
      font-weight: bold;
    }

    .carousel-indicators {
      position: relative;
      bottom: 0;
      left: auto;
      width: 100%;
      margin-left: 0;
      margin-bottom: 0;
      li {
        border-radius: 0;
        margin: 1px;
        width: 14px;
        height: 8px;
        background-color: #999;
        border: none;
        &.active {
          background-color: #041e41;
        }
      }
    }
  }

}


footer {
  background-color: $blue;
  overflow: hidden;
  position: relative;
  color: #fff;
  padding: 40px 0;
  @include tablet {
    padding: 60px 0 40px;
  }
  &:before {
    content: '';
    background: url(../images/footer.jpg) no-repeat top;
    background-size: cover;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.05;
  }

  .col-sm-6 {
    z-index: 10;
  }

  .navbar-logo {
    max-width: 270px;
    display: block;
    margin-bottom: 20px;
  }

  h3, p, a, div {
    color: #fff;
  }

  .about {
    @include tablet {
      min-height: 310px;
    }
    @include laptop {
      min-height: auto;
    }
    p {
      font-size: 15px;
    }
  }

  h3 {
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 25px;
  }

  .nav {
    margin-top: -10px;
    li {
      border-bottom: 1px solid #4a505c;
      line-height: normal;
      a {
        padding: 8px 0;

        &:hover, &:focus {
          background-color: transparent;
        }
      }
    }
  }

  @media (max-width: $width_tablet) {
      .nav li a,
      .about p,
      .address,
      .phone-fax,
      a[itemprop='url'],
      .social-media,
      h3 {
          text-align: center;
      }
      .navbar-logo {
          margin-left: auto;
          margin-right: auto;
      }
      .social-media {
          margin-left: 10px;
      }
  }

  .primary {
    .nav {
      li {
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }

  .social-media {
    margin-top: 15px;
    .fa {
      font-size: 20px;
      margin-right: 8px;
    }
  }

  .logo-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.05;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    @include tablet {
      height: auto;
    }
    @include laptop {
      top: -12%;
      left: 75%;
      height: 135%;
    }
  }
}



#copyright {
  text-align: center;
  color: #fff;
  background-color: #000;
  font-size: 12px;
  padding: 10px 0;

  .linksCopy {
    @include laptop {
      float: left;
    }
    a {
      color: #fff;
    }
  }

  .clientCopy {
    @include laptop {
      float: right;
    }
  }
}
