@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300);

// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #1d3453;
$blue:                  #3b4350;
$white:                 #f7f7f7;
$grey:                  #d5d5d5;
$para:                  #525252;
$heading:               #313131;

// Fonts
$brand-font:            'Roboto', sans-serif;

$sideNavWidth: 240px;
$sideNavDirection: (right);

$button_width: 40px;     // width of button area
$button_height: 80px;     // height of button area
$bar_thickness: 6px;     // thickness of button bars
$bar_roundness: 6px;
$button_pad: 0px;      // left/right padding between button area and bars.
$button_bar_space: 8px;     // spacing between button bars
$button_transistion_duration: 0.3s;  // transition duration
$hamburger_normal: $brand-primary;
$hamburger_active: $brand-primary;

// Breakpoint variables
$width_mobile: 480px;
$width_phablet: 560px;
$width_tablet: 768px;
$width_laptop: 992px;
$width_desktop: 1200px;



/*
 * Mixins
 */
// Media query mixins
@mixin mobile { @media (min-width: #{$width_mobile}) { @content; } }
@mixin phablet { @media (min-width: #{$width_phablet}) { @content; } }
@mixin tablet { @media (min-width: #{$width_tablet}) { @content; } }
@mixin laptop { @media (min-width: #{$width_laptop}) { @content; } }
@mixin desktop { @media (min-width: #{$width_desktop}) { @content; } }

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
